<template>
  <Layout>
    <v-card :loading="loading">
      <v-card-text class="py-0 pb-6">
        <v-row>
          <v-col cols="12">
            <v-row class="align-center">
              <v-col cols="2" class="text-center" TITLE>
                <v-icon size="40" color="primary">quiz</v-icon>
              </v-col>
              <v-col cols="10">
                <h1>
                  {{i18n.results}}
                </h1>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              :items="serveys"
              v-model="uid"
              :label="i18n.surveys"
              outlined
              item-text="name"
              item-value="uid"
              dense
              clearable
              @change="loadSurvey()"
              hide-details
            >
              <template v-slot:selection="{ attr, on, item, selected }">
                <span
                  v-bind="attr"
                  :input-value="selected"
                  v-on="on"
                >
                  <v-chip x-small>{{item.name}}</v-chip>
                </span>
              </template>
              <template v-slot:item="{ item }">
                <span
                >
                  {{item.name}}
                </span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12">
            <h2>{{i18n.stats}}</h2>
          </v-col>
          <v-col cols="12" class="py-1" v-if="!loading && uid">
            <SurveyQuestionResponsesLayout :uid="uid" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="load" small class="mt-2">reload</v-btn>
      </v-card-actions>
    </v-card>
  </Layout>
</template>
<script>
import Layout from '@/components/Layout'
import PreInstructions from '@/components/PreInstructions'
import SurveyUserResponsesLayout from '@/components/SurveyUserResponsesLayout'
import SurveyQuestionResponsesLayout from '@/components/SurveyQuestionResponsesLayout'
import SurveyResult from '@/components/SurveyResult'

export default {
  components: { Layout, PreInstructions, SurveyUserResponsesLayout, SurveyQuestionResponsesLayout, SurveyResult },
  data() {
    return {
      loading:false,
      survey_data:null,
      show_instructions:true,
      show_survey:false,
      uid:null,
      serveys:[]
    }
  },
  methods:{
    start(){
      this.instructions = false
      this.show_survey = true
    },
    
    async loadSurvey(){
      return new Promise(async (res, rej)=>{
        this.loading = true
        this.$store.state.survey = await this.$store.dispatch('getResource', {resource:'/survey-results/' + this.uid})
        this.loading = false
        res(true)
      })
    },
    async loadSurveys(){
      return new Promise(async (res, rej)=>{
        this.loading = true
        this.serveys = await this.$store.dispatch('getResource', {resource:'/all-surveys'})
        this.loading = false
        res(true)
      })
    },
    goTo(path) {
      this.$router.push(path).catch(x=>{})
    },
    async load(){
      
      await this.loadSurveys()
    }
  },
  computed:{
     i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    language(){
      return this.$store.state.language
    },
    visible_questions(){
      return this.$store.state.survey && this.$store.state.survey.survey_questions
      .filter(x=>!x.user_survey_question_response)
    },
    survey(){
      return this.$store.state.survey
    },
  },
  async created(){
    await this.load()
  }
}
</script>